
import 'jquery-validation'
$(document).ready(function () {

    // show + / - in number input | static form in message
    $(".order-form .panel [id*='orderItems'] > div").append('<div class="inc button-input">+</div><div class="dec button-input">-</div>');

    // show + / - in number input | in small order in message
    var numberInput = $('body .container .pad__orderpromotion form input');
    numberInput.parent().append('<div class="inc button-input">+</div><div class="dec button-input">-</div>');

    // button for inc and dec imput type number
    $(".button-input").on("click", function () {
        var $button = $(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.text() == "+") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        $button.parent().find("input").val(newVal);
    });

    // show client message
    $('.js__show_message').on('click', function () {
        var stat = this.dataset.id;

        $('#js__sendbox').hide();
        $('#js__message_' + stat).show();

        if ($(this).data('marketingMessage')){

        }else{
            updateClientInboxMessage(stat);
        }

    });

    // show client sandbox
    $('.js__back_to_sandbox').on('click', function () {
        $(this).closest('.section--inbox').hide();
        $('#js__sendbox').show();
    });

    // detect which message is shown
    $('section[id^="js__message"]').each(function () {
        if ($(this).is(':visible')) {
            updateClientInboxMessage(this.dataset.id);
        }
    });

    $('.js__answer_send button').on('click', function (e) {
        e.preventDefault();

        var formData = $(this).parent();
        var stat = $(this).parent().find('input[name^="questionnaire_"][name$="[id]"]').val();

        saveAnswer(stat, formData);
    });

    $('button.close').on('click', function () {
        $(this).parent().remove();
    });

    /*
     Hide message if message show time end
     */
    $('section[id^="js__message_"]').each(function () {
        var message = $(this);
        var i = 0;
        var showMessage = setInterval(function () { //change to message end date
            if (message.data('end')) {
                var countDownDate = new Date(message.data('end')).getTime();
                var now = Math.floor(new Date().getTime() / 1000);

                if ((countDownDate - now) <= 0) {
                    var id = message.data('hash');
                    $('a[data-hash="' + id + '"]').remove();
                    $('section[data-hash="' + id + '"]').remove();
                    clearInterval(showMessage);
                }
            } else {
                clearInterval(showMessage);
            }
        }, 2000);
    });
    // End client sandbox


    // Start sales sandbox
    var sales = $('#js__sales_sendbox').data('sales');
    // show sales message
    $('.js__show_sales_message').on('click', function () {
        var stat = this.dataset.id;

        $('#js__sales_sendbox').hide();
        $('#js__sales_message_' + stat).show();

        updateSalesMessageStats(stat);
    });
    // show sales sandbox
    $('.js__back_to_sales_sandbox').on('click', function () {
        $('#js__sales_message_' + this.dataset.id).hide();
        $('#js__sales_sendbox').show();
    });

    // detect which message is shown
    $('section[id^="js__sales_message"]').each(function () {
        if ($(this).is(':visible')) {
            var stat = $('a[data-hash="' + this.dataset.hash + '"]')[0].dataset.id;

            updateSalesMessageStats(stat);
        }
    });


    $('.js__sales_answer_send button').on('click', function (e) {
        e.preventDefault();

        var formData = $(this).parent();
        var stat = $(this).parent().find('input[name^="questionnaire_"][name$="[id]"]').val();

        saveSalesAnswer(stat, formData);
    });

    $('button.close').on('click', function () {
        $(this).parent().remove();
    });

    /*
     Hide message if message show time end
     */
    $('section[id^="js__sales_message_"]').each(function () {
        var message = $(this);
        var i = 0;
        var showMessage = setInterval(function () { //change to message end date
            if (message.data('end')) {
                var countDownDate = new Date(message.data('end')).getTime();
                var now = Math.floor(new Date().getTime() / 1000);

                if ((countDownDate - now) <= 0) {
                    var id = message.data('hash');
                    $('a[data-hash="' + id + '"]').remove();
                    $('section[data-hash="' + id + '"]').remove();
                    clearInterval(showMessage);
                }
            } else {
                clearInterval(showMessage);
            }
        }, 2000);
    });
    // End sales sandbox

    // video play
    $('.btn-play').on('click', function (e) {
        e.preventDefault();

        var parent = $(this).parent();
        parent.find('.video-overlay', parent).remove();
        parent.find('.btn-play', parent).remove();
        $(this).remove();

        $(parent.find('video')).on('play', function () {
            $(parent.find('video')).attr('controls', '');
        });
    });

    // Message verification by manager
    $('.js__manager_verification_link').on('click', function (e) {
        e.preventDefault();

        $.post(Routing.generate('manager_message_verification_type', {
            type: $(this).data('action'),
            message: $(this).data('message')
        }), {})
            .done(function (response) {
                if (response.message) {
                    $('#js__manager_verification').find('a').each(function () {
                        if ($(this).data('action') == response.action) {
                            $(this).parent().removeClass("col-md-6").addClass("col-md-12");
                            $(this).text(response.label);
                        } else {
                            $(this).parent().remove();
                        }
                    });
                } else {
                }
            });
    });

    jQuery.extend(jQuery.validator.messages, {
        required: "To pole jest wymagane.",
        remote: "Please fix this field.",
        email: "Proszę podać poprawny adres email.",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid date (ISO).",
        number: "Proszę podać poprawną liczbę.",
        digits: "Możliwe tylko liczby.",
        creditcard: "Please enter a valid credit card number.",
        equalTo: "Please enter the same value again.",
        accept: "Please enter a value with a valid extension.",
        maxlength: jQuery.validator.format("Please enter no more than {0} characters."),
        minlength: jQuery.validator.format("Please enter at least {0} characters."),
        rangelength: jQuery.validator.format("Please enter a value between {0} and {1} characters long."),
        range: jQuery.validator.format("Please enter a value between {0} and {1}."),
        max: jQuery.validator.format("Please enter a value less than or equal to {0}."),
        min: jQuery.validator.format("Proszę podać wartość większą niż {0}.")
    });

    $('form[name="single_client_order"]').validate({
        ignore: false,
        rules: {},
        errorClass: 'form-error',
        validClass: 'success',
        errorElement: 'span',
        highlight: function (element, errorClass, validClass) {
            if (element.type == "number") {
                var panel = $('#' + $(element).closest('*[role=tabpanel]')[0].id);

                if (!panel.hasClass('in')) {
                    panel.collapse('show');
                }
            }

            jQuery(element).addClass(errorClass).removeClass(validClass);
        },
        unhighlight: function (element, errorClass, validClass) {
            jQuery(element).removeClass(errorClass).addClass(validClass);
        },
    });

    $('form[name="single_client_order"] .js__only_numbers_allow').each(function () {
        $(this).rules('add', {
            number: true,
            min: 0
        });
    });

    $('form[name="single_client_order"] input[type="text"]').each(function () {
        $(this).rules('add', {
            required: true
        });
    });

    $('form[name^="client_order"]').each(function () {
        $(this).validate({
            ignore: false,
            errorClass: 'form-error',
            validClass: 'success',
            errorElement: 'span',
            highlight: function (element, errorClass, validClass) {
                jQuery(element).addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function (element, errorClass, validClass) {
                jQuery(element).removeClass(errorClass).addClass(validClass);
            },
            submitHandler: function (form) {
                var message = $(form).data('message');
                var client = $(form).data('client');
                var additional = $(form).data('additional');

                $('.js__loading_' + message + '_' + client).show();
                $('.js__thanks_' + message + '_' + client).hide();

                $.post(Routing.generate('ajax_message_make_client_order', {
                    'message': message,
                    'client': client,
                    type: additional
                }), $(form).serialize())
                    .done(function (response) {
                        if (response.message) {
                            $(form).hide();
                            $('.js__order_link_' + message + '_' + client).hide();
                            $('.js__loading_' + message + '_' + client).hide();
                            $('.js__thanks_' + message + '_' + client).show();

                            $(form).css("display", "none");
                            $('.js__order_link_' + message + '_' + client).css("display", "none");
                            $('.js__loading_' + message + '_' + client).css("display", "none");
                            $('.js__thanks_' + message + '_' + client).css('display', 'block');


                            $(form).find('input').each(function () {
                                $(this).val('');
                            });
                            $(form).find('.js__only_numbers_allow').each(function () {
                                $(this).val(0);
                            });

                        } else {
                            $(form).show();
                            $('.js__order_link_' + message + '_' + client).show();
                            $('.js__loading_' + message + '_' + client).hide();
                            $('.js__thanks_' + message + '_' + client).hide();

                            $(form).css('display', 'block');
                            $('.js__order_link_' + message + '_' + client).css('display', 'block');
                            $('.js__loading_' + message + '_' + client).css("display", "none");
                            $('.js__thanks_' + message + '_' + client).css("display", "none");
                        }
                    });
            },
        });
    });

    $('form[name^="client_order"] .js__only_numbers_allow').each(function () {
        $(this).rules('add', {
            number: true,
            min: 0
        });
    });

    $('form[name^="client_order"] input[type="text"]').each(function () {
        $(this).rules('add', {
            required: true
        });
    });

    $('.js__send_verification_btn').on('click', function () {

        var form = $(this).parent().parent('form');

        var message = form.data('message');
        var client = form.data('client');
        var modalId = '#client_order_verification_modal_' + message + '_' + client;

        $.post(Routing.generate('ajax_check_client_order_number', {
            'message': message,
            'client': client,
        }), form.serialize())
            .done(function (response) {
                if (response.message == 1) {
                    form.find('input').removeClass('text-danger');
                    $(modalId).modal('hide');

                    if ($('form[name=client_order_promotion_' + message + '_' + client + ']').length) {
                        $('form[name=client_order_promotion_' + message + '_' + client + ']').submit();
                    }

                    if ($('form[name=single_client_order]').length) {
                        $('form[name=single_client_order]').submit();
                    }
                    $(modalId + ' .js__verification_error').hide();
                    $(modalId + ' .js__verification_blocked').hide();
                } else {
                    if (response.message == 0) {
                        $(modalId + ' .js__verification_error').show();
                        $(modalId + ' .js__verification_blocked').hide();
                    } else {
                        $(modalId + ' .js__verification_blocked').show();
                        $(modalId + ' .js__verification_error').hide();
                    }
                    form.find('input').addClass('text-danger');
                }
            });
    });


    $('.js__order_link').on('click', function () {
        var messageId = $(this).data('message');
        $('.order_form_overlay_' + messageId).show();
    });

    var regex = new RegExp('^[0-9]*$');
    $('form[name="single_client_order"] .js__only_numbers_allow').each(function () {
        $(this).inputFilter(function (value) {
            return regex.test(value);
        });

        $(this).focusout(function () {
            if (!$(this).val()) {
                $(this).val(0);
            }
        });
    });

    $('form[name^="client_order"] .js__only_numbers_allow').each(function () {
        $(this).inputFilter(function (value) {
            return regex.test(value);
        });

        $(this).focusout(function () {
            if (!$(this).val()) {
                $(this).val(0);
            }
        });
    });
});

(function ($) {
    $.fn.inputFilter = function (inputFilter) {
        return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function () {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            } else {
                this.value = "";
            }
        });
    };
}(jQuery));

function saveAnswer(stat, formData) {

    $('#js__answer_send_' + stat + ' .loading').show();
    $('#js__answer_send_' + stat + ' .error-msg').hide();

    $.ajax({
        type: "POST",
        url: Routing.generate('save_answer', {id: stat}),
        data: formData.serialize(),
        dataType: 'json',
        success: function (result) {
            if (result.result) {
                $('#js__answer_send_' + stat + ' .loading').hide();
                $('#js__answer_send_' + stat + ' .question').hide();
                $('#js__answer_send_' + stat + ' form').hide();
                $('#js__answer_send_' + stat + ' .thank-you-msg').show();
            } else {
                $('#js__answer_send_' + stat + ' .loading').hide();
                $('#js__answer_send_' + stat + ' .error-msg').show();
            }
        }
    });
}

function updateClientInboxMessage(stat) {
    if (!window.location.search.includes('no-stat-update')) {
        $.post(Routing.generate('update_message_stats', {id: stat}), {})
            .done(function (response) {
                if (response) {
                    $('.js__back_to_sandbox').on('click', function () {
                        $(this).closest('.section--inbox').remove();
                        $('.js__show_message[data-id="' + stat + '"]').remove();
                        $('#js__sendbox').show();
                    });
                }
            }).always(function (response) {
        });
    }
}

function saveSalesAnswer(stat, formData) {

    var path = $("#js__sales_sendbox").data('path');

    $('#js__sales_answer_send_' + stat + ' .loading').show();
    $('#js__sales_answer_send_' + stat + ' .error-msg').hide();


    $.ajax({
        type: "POST",
        url: Routing.generate('save_' + path + '_answer', {stat: stat}),
        data: formData.serialize(),
        dataType: 'json',
        success: function (result) {
            if (result.result) {
                $('#js__sales_answer_send_' + stat + ' .loading').hide();
                $('#js__sales_answer_send_' + stat + ' .question').hide();
                $('#js__sales_answer_send_' + stat + ' form').hide();
                $('#js__sales_answer_send_' + stat + ' .thank-you-msg').show();
            } else {
                $('#js__sales_answer_send_' + stat + ' .loading').hide();
                $('#js__sales_answer_send_' + stat + ' .error-msg').show();
            }
        }
    });
}

function updateSalesMessageStats(stat) {

    var path = $("#js__sales_sendbox").data('path');

    $.post(Routing.generate('update_' + path + '_message_stats', {id: stat}), {})
        .done(function (response) {
            if (response) {
                $('.js__back_to_sales_sandbox').on('click', function () {
                    $('#js__sales_message_' + stat).remove();
                    $('.js__show_sales_message[data-id="' + stat + '"]').remove();
                    $('#js__sales_sendbox').show();
                });
            }
        });
}
